

@import "~@fluentui/react/dist/sass/Fabric";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');


body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

$smallScreenBreakPoint: 720px;
$smallPadding: 16px;
$bigPadding: 32px;

$blGreen: rgba(106, 190, 67, 1);
$blBlue: rgba(16, 110, 190, 1);
$blGrey: rgba(211, 211, 210, 1);

@keyframes MoveUpDown {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

#root {

    font-family: 'Poppins', "Century Gothic", "CenturyGothic", "century-gothic", "AppleGothic", sans-serif;
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;

    .ms-Grid {
        padding: 0;
    }

    .page {

        .animated-arrow-down {
            animation: MoveUpDown 1s infinite alternate;
        }

        .menu {

            position: relative;
            box-shadow: rgba(149, 157, 165, 0.2) 0 2px 4px;

            .menu-sm {

                .menu-sm-wrapper {

                    $burger--thickness: 4px;
                    $width: 28px;
                    $navigation__transition: height 0.5s ease-in-out;

                    height: 22px;
                    text-align: right;
                    z-index: 5;

                    .burger {

                        display: inline-block;
                        border: 0;
                        background: none;
                        outline: 0;
                        padding: 0;
                        position: relative;
                        cursor: pointer;

                        border-bottom: $burger--thickness solid currentColor;
                        width: $width;

                        transition: border-bottom 1s ease-in-out;
                        -webkit-transition: border-bottom 1s ease-in-out;

                        // Fix for extra space in Firefox
                        &::-moz-focus-inner {
                            border: 0;
                            padding: 0;
                        }

                        &:before {
                            content: "";
                            display: block;
                            border-bottom: $burger--thickness solid currentColor;
                            width: 100%;
                            margin-bottom: 5px;
                            transition: transform 0.5s ease-in-out;
                            -webkit-transition: -webkit-transform 0.5s ease-in-out;
                        }

                        &:after {
                            content: "";
                            display: block;
                            border-bottom: $burger--thickness solid currentColor;
                            width: 100%;
                            margin-bottom: 5px;
                            transition: transform 0.5s ease-in-out;
                            -webkit-transition: -webkit-transform 0.5s ease-in-out;
                        }
                    }
                    .burger-check {
                        display: none;
                    }
                    .burger-check:checked ~ .burger {
                        border-bottom: $burger--thickness solid transparent;
                        transition: border-bottom 0.8s ease-in-out;
                        -webkit-transition: border-bottom 0.8s ease-in-out;
                        top: 7px;

                        &:before {
                            transform: rotate(-405deg) translateY(1px) translateX(-3px);
                            -webkit-transform: rotate(-405deg) translateY(1px) translateX(-3px);
                            transition: transform 0.5s ease-in-out;
                            -webkit-transition: -webkit-transform 0.5s ease-in-out;
                        }

                        &:after {
                            transform: rotate(405deg) translateY(-4px) translateX(-5px);
                            -webkit-transform: rotate(405deg) translateY(-4px) translateX(-5px);
                            transition: transform 0.5s ease-in-out;
                            -webkit-transition: -webkit-transform 0.5s ease-in-out;
                        }
                    }

                    .navigation {
                        overflow: hidden;
                        position: absolute;
                        top: 78px;
                        height: 0;
                        left: 0;
                        width: 100vw;
                        padding: 0;
                        color: white;
                        text-align: center;
                        background: $blGreen;

                        transition: $navigation__transition;

                        a {
                            font-size: 24px;
                            color: white;
                            padding: 5px;
                            text-decoration: none;
                            border-bottom: 4px solid transparent;
                            margin: 0 auto;

                            &.active {
                                border-bottom: 4px solid rgba(16, 110, 190, 1);
                            }
                        }
                    }

                    .burger-check:checked ~ .navigation {
                        height: calc(100vh - 78px - 100px);
                        box-shadow: rgba(50, 50, 93, 0.5) 0 2px 5px -1px, rgba(0, 0, 0, 0.5) 0 1px 3px -1px;

                        transition: $navigation__transition;
                    }
                }

            }

            .menu-bg {
                a {
                    color: rgba(0, 0, 0, 0.9);

                    text-decoration: none;
                    font-size: 15px;
                    font-weight: 500;
                    padding-top: 20px;

                    &:hover,
                    &.active {
                        &::before {
                            width: 100%;
                            margin-right: -100%;
                        }
                    }

                    &::before,
                    {
                        content: "";
                        text-align: center;
                        display: inline-block;
                        height: 3px;
                        width: 0;
                        margin-right: 0;
                        vertical-align: -100%;
                        border-bottom: 4px solid rgba(106, 190, 67, 1);
                        transition: all 0.2s;
                    }
                }
            }
        }

        .content {

            margin-top: 4px;

            .home-hero {
                .hero {
                    .hero-wrapper {
                        margin: 0;
                        text-align: start;
                        h1, h2, h3, h4 {
                            color: inherit;
                            margin: 0;
                        }

                        h2 {
                            margin: 32px 0;
                            font-weight: 400;
                        }

                        @media (max-width: $smallScreenBreakPoint) {
                            h1 {
                                margin-top: 16px;
                                //text-align: center;
                            }
                        }
                    }
                }
            }

            .hero {
                box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0 1px 3px -1px;

                .hero-wrapper {

                    margin: auto;
                    text-align: center;

                    h4 {
                        color: rgba(0, 0, 0, 0.4);
                        font-weight: 700;
                        text-transform: uppercase;
                        font-size: 14px;
                        line-height: 150%;
                        margin: auto;
                    }

                    h1 {
                        font-weight: 600;
                        font-size: 32px;
                        line-height: 150%;
                        color: $blGreen;
                        margin: auto;
                    }

                    h2 {
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 150%;
                        margin: 16px auto;
                    }

                    h3 {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 180%;
                        margin: 8px auto;
                    }

                    @media (max-width: $smallScreenBreakPoint) {
                        h1 {
                            font-size: 26px;
                        }
                        h2 {
                            font-size: 22px;
                        }
                        h3 {
                            font-size: 14px;
                        }
                        h4 {
                            font-size: 12px;
                        }
                    }
                }

            }

            .service-link {

                text-decoration: none;
                width: 100%;
                display: block;
                border-radius: 4px;
                box-shadow: rgba(50, 50, 93, 0.15) 0 2px 5px -1px, rgba(0, 0, 0, 0.2) 0 1px 3px -1px;
                color: rgba(0, 0, 0, 0.8);
                background: rgba(255, 255, 255, 0.9);
                position: relative;

                transition: background 0.5s;

                h3 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%;
                    margin-bottom: 0;
                }

                .service-link-details {
                    height: 150px;
                }

                .sub-link {
                    color: $blGreen;
                    font-weight: 500;
                }

                &:hover {
                    color: white;
                    background: $blBlue;

                    .sub-link {
                        color: white;
                    }
                }

            }
        }

        footer {
            margin: -15px auto 0;

            .footer-wrapper {
                padding: 0 32px 14px 32px;

                a {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
    }

}
